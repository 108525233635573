@import 'modern-normalize';

body {
  color: #333;
  min-width: 1200px;
}

a {
  color: #F7931A;
  text-decoration: none;
  transition: color 0.2s ease;
}
a:hover {
  color: #F25D1F;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2em;
  box-sizing: content-box;
}

header {
  padding: 60px 0 30px;
  background: #F1F1F1;
  margin-bottom: 2em;
}

header .bitcoin-logo {
  width: 100px;
}

header .arm {
  width: 90px;
}

header h1 {
  font-size: 1.6em;
  margin: 10px 0 0;
}

table.results {
  width: 100%;
  border-spacing: 0;
}

table.results thead {
  font-weight: bold;
}

table.results td {
  padding: 1.4em 0;
  border-bottom: 1px solid #ECECEC;
}
table.results thead td {
  border-top: 1px solid #ECECEC;
  padding-right: 2em;
}

table.results tbody td:first-child {
  position: relative;
  padding-left: 2.6em;
}
table.results tbody td:first-child img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2em;
}

table.results thead [data-sort] {
  position: relative;
  cursor: pointer;
  color: #333;
}

table.results thead [data-sort]:before,
table.results thead [data-sort]:after {
  content: '';
  display: block;
  position: absolute;
  right: -1em;
  transform: translateY(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
table.results thead [data-sort]:before {
  border-bottom: 4px solid #BFBFBF;
  top: calc(50% - 3px);
}
table.results thead [data-sort]:after {
  border-top: 4px solid #BFBFBF;
  top: calc(50% + 3px);
}
table.results[data-sort-order="asc"] thead [data-sort-active]:before {
  border-bottom-color: #333;
}
table.results[data-sort-order="desc"] thead [data-sort-active]:after {
  border-top-color: #333;
}

.footer {
  margin-top: 2em;
  padding: 1em 0;
  color: #9A9A9A;
  background: #F1F1F1;;
}

.footer .me {
  float: right;
}
